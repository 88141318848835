<script>

</script>
<main>
  <div class="page-title"><h2>Le sostanze</h2></div>
  <div class="page-text">
    <p>Diverse sono le sostanze considerate inquinanti atmosferici. Le centraline presenti all'interno del comune ne rilevano la concentrazione al fine di determinare la qualità dell'aria. Ma cosa sono, e quali effetti hanno sulla nostra salute, queste sostanze?</p>
    <h4>PM 10</h4>
    <p>La sigla PM10 (<em>Particulate Matter</em> o Materia Particolata, cioè in piccolissime particelle) identifica una delle numerose frazioni in cui viene classificato il particolato. Si tratta di materiale presente nell'atmosfera in forma di particelle microscopiche. Secondo ricerche sperimentali su pazienti di città USA e a Milano il particolato riduce l'aspettativa di vita di 1-2 anni; il PM10 aumenta l'asma tutto l'anno e le bronchiti in inverno (<a href="https://it.wikipedia.org/wiki/PM10" title="PM 10 su Wikipedia">Wikipedia</a>).</p>
    <h4>PM 2,5</h4>
    <p>Con il termine PM 2,5 si raggruppano tutte le particelle aventi dimensioni minori o uguali a 2,5 µm. Di massima più il numero è basso, più le polveri sono sottili ed anche più pericolose per la salute della specie umana ed animale. Infatti il PM 2,5 è in grado di penetrare negli alveoli polmonari con eventuale diffusione nel sangue. Nelle donne ci sono evidenza che il PM 2,5 venga ad accumularsi nel seno causando il cancro al seno (<a href="https://it.wikipedia.org/wiki/PM_2.5" title="PM 2,5 su Wikipedia">Wikipedia</a>).</p>
    <h4>Monossido di Azoto</h4>
    <p>Il monossido di azoto è un gas incolore; è un forte ossidante e reagisce con materiali combustibili e riducenti. Al contatto con l'aria reagisce con l'ossigeno trasformandosi in biossido di azoto. Il monossido di azoto è irritante per gli occhi ed il tratto respiratorio. L'inalazione può causare edema polmonare, inoltre può avere effetti sul sangue, causando formazione di metaemoglobina. Nei casi più gravi può portare alla morte. Le principali fonti di NO di origine umana sono dovute ad attività civili ed industriali che comportano processi di combustione, come i trasporti e la produzione di calore ed elettricità  (<a href="https://it.wikipedia.org/wiki/Monossido_di_azoto" title=">Monossido di Azoto su Wikipedia">Wikipedia</a>).</p>
    <h4>Diossido di Azoto</h4>
    <p>Il diossido di azoto è un gas rosso bruno a temperatura ordinaria dall'odore soffocante, irritante e caratteristico. È un forte irritante delle vie polmonari; già a moderate concentrazioni nell'aria provoca tosse acuta, dolori al torace, convulsioni e insufficienza circolatoria (<a href="https://it.wikipedia.org/wiki/Diossido_di_azoto" title="Diossido di Azoto su Wikipedia">Wikipedia</a>).</p>
    <h4>Ozono</h4>
    <p>L'ozono è una forma allotropica dell'ossigeno, dal caratteristico odore agliaceo. Le sue molecole sono formate da tre atomi di ossigeno. È presente negli strati alti dell'atmosfera concentrandosi soprattutto a 25 km di altezza dove è presente l'ozonosfera: è considerato un gas serra, ma diversamente da altri gas serra che trattengono l'energia proveniente dalla superficie terrestre, l'ozono assorbe e trattiene parte dell'energia proveniente direttamente dal Sole. L'ozono è presente in piccola parte anche negli strati più bassi dell'atmosfera (è uno dei principali componenti dello smog prodotto dall'uomo nelle grandi città): diversamente dall'ozono che si trova nella stratosfera, quello troposferico risulta essere un inquinante molto velenoso se respirato a grandi dosi (<a href="https://it.wikipedia.org/wiki/Ozono" title="Ozono su Wikipedia">Wikipedia</a>).</p>
    <h4>Anidride Solforosa</h4>
    <p>L'anidride solforosa (anche detta biossido di zolfo o ossido di zolfo) è un gas incolore dal tipico odore di bruciato. La sostanza è fortemente irritante e nociva per gli occhi e il tratto respiratorio: per inalazione può causare edema polmonare acuto ed una prolungata esposizione può portare alla morte. La presenza di anidride solforosa nell'aria può essere avvertita dalla comparsa di un sapore metallico in bocca, anche in concentrazioni al di sotto della soglia olfattiva (<a href="https://it.wikipedia.org/wiki/Anidride_solforosa" title="Anidride Solforosa su Wikipedia">Wikipedia</a>).</p>
    <h4>Benzene</h4>
    <p>Il benzene è un composto chimico che a temperatura ambiente e pressione atmosferica si presenta sotto forma di liquido volatile incolore altamente infiammabile, dall'odore caratteristico. Viene da tempo impiegato come antidetonante nelle benzine, ma a causa della sua pericolosità per la salute e della facilità con cui contamina le falde freatiche, diverse entità (tra cui gli Stati Uniti e l'Unione europea) ne stanno scoraggiando l'uso limitandone le concentrazioni ammesse per legge. L'inalazione di un tasso molto elevato di benzene può portare al decesso, mentre tassi più bassi possono generare sonnolenza, vertigini, tachicardia, mal di testa, tremori, stato confusionale o perdita di coscienza. Il principale effetto di un'esposizione cronica al benzene è il danneggiamento dei tessuti ossei e la diminuzione delle cellule del midollo osseo, che può causare una diminuzione del tasso di globuli rossi nel sangue e un'anemia aplastica o una leucemia. Il benzene è stato classificato dall'IARC come agente carcinogeno del gruppo 1 (<a href="https://it.wikipedia.org/wiki/Benzene" title="Benzene su Wikipedia">Wikipedia</a>).</p>
    <h4>Carbon Black</h4>
    <p>Il carbon black (in italiano nero di carbone o più propriamente, nero di carbonio, o nerofumo) è un pigmento prodotto dalla combustione incompleta di prodotti petroliferi pesanti quali catrame di carbone fossile, catrame ottenuto dal cracking dell'etilene, o da grassi ed olii vegetali. L'Agenzia internazionale per la ricerca sul cancro (IARC), ha valutato che il "nero carbonio è probabilmente cancerogeno". L'esposizione a breve termine ad alte concentrazioni di nero di carbonio in polvere può causare disagio al tratto respiratorio superiore, attraverso irritazione meccanica. Le particelle di nero di carbone assorbono radiazioni solari e radiazioni infrarosse e per questo motivo, se sospese in atmosfera, hanno un potenziale di riscaldamento globale molto elevato. Al momento, in Italia, non sono stati definiti limiti normativi alla sua concentrazione in aria (<a href="https://it.wikipedia.org/wiki/Nero_di_carbone" title="Carbon Black su Wikipedia">Wikipedia</a>).</p>
  </div>
</main>
<style>

</style>